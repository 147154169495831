import { Injectable } from '@angular/core';

const TOKEN_KEY = "AuthToken";

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  constructor() { }

  setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }
  
  removeToken() {
    if (localStorage.getItem(TOKEN_KEY)){
      localStorage.removeItem(TOKEN_KEY)
    }
  }
}
