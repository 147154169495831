import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID,NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditCampComponent } from './editBot/edit-camp/edit-camp.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { TokenInterceptor } from './auth/token.interceptor';
import { ResetPasswordComponent } from './reset_password/reset-password/reset-password.component';
import { SpinnerLoadingModule } from './spinner-loading/spinner-loading.module';
import { CreateBotComponent } from './create-bot/create-bot.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import localEsp from '@angular/common/locales/es-MX'

registerLocaleData(localEsp, 'es-Es')

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EditCampComponent,
    ResetPasswordComponent,
    CreateBotComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CheckboxModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ToastModule,
    SpinnerLoadingModule,
    ButtonModule,
    InputTextModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  { provide: LOCALE_ID, useValue: 'es-Es' } ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
