<p-toast position="top-right" key="toast"></p-toast>

<div class="row" style="height: 80%">
    <div class="col-12">

        <div *ngIf="validando"></div>

        <div  *ngIf="!validando" class="card login shadow p-4" style="height: auto;">
            <div class="row">
                <div class="col-10 col-md-10">
                    <h4 class="text-login">Cambiar contraseña</h4>
                </div>
                <div class="col-2 col-md-2">
                    <img class="logo-login" src="assets/img/ICO_WEB_2020.png" alt="logo">
                </div>
            </div>

            <form *ngIf="page==1">
                <div class="form-group p-2">
                    <label for="password">Ingrese su correo electrónico</label>
                    <input type="text" name="email" [(ngModel)]="email" class="form-control inp">
                </div>

                <div class="form-group p-2" (click)="checkEmail()">

                    <div class="button">
                        <a style="background-image: none;">
                            Cambiar contraseña
                        </a>
                        <div class="mask"></div>
                    </div>
                </div>
                <div class="form-group p-2" style="text-align: center;">
                    <a routerLink="/login">Cancelar</a>
                </div>
                <app-spinner-loading *ngIf="loading"></app-spinner-loading>
            </form>

            <div class="row" *ngIf="page == 2" style="text-align: center; margin-top: 5%;">
                <label>Se ha enviado un mensaje al correo electrónico ingresado para continuar con su solicitud.</label>
            </div>

            <form *ngIf="page==3">
                <div class="form-group p-2">
                    <label for="password">Ingrese su nueva contraseña</label>
                    <input type="password" name="password" [(ngModel)]="password" class="form-control inp">
                </div>

                <div class="form-group p-2">
                    <label for="password">Repita su contraseña</label>
                    <input type="password" name="rePassword" [(ngModel)]="passwordConfirm" class="form-control inp">
                </div>

                <div class="form-group p-2" (click)="clickChangePassword()">

                    <div class="button">
                        <a style="background-image: none;">
                            Cambiar contraseña
                        </a>
                        <div class="mask"></div>
                    </div>
                </div>
                <app-spinner-loading *ngIf="loading"></app-spinner-loading>
            </form>

        </div>
    </div>

</div>