import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Menu, Pregunta } from '../models/chatbot';

@Component({
  selector: 'app-create-bot',
  templateUrl: './create-bot.component.html',
  styleUrls: ['./create-bot.component.scss'],
  providers: [MessageService]
})
export class CreateBotComponent implements OnInit {


  menus: Menu[] = []
  sizeMenu: number
  config: boolean = false
  createMain: boolean = false
  menuConfig: Menu



  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
  }

  createCategories(){
    this.menus = []
    for(let i=0; i<this.sizeMenu; i++){
      let categoria = new Menu()
      categoria.id = i + 1
      this.menus.push(categoria)
    }    
  }

  createQuestionByCategory(){
    if(this.menuConfig.preguntas.length == 10){
      this.alertMaxQuestion()
    } else {
      let newQuestion = new Pregunta
      this.menuConfig.preguntas.push(newQuestion)
    }
    
  }

  removeQuestion(question:Pregunta){
    var indice = this.menuConfig.preguntas.indexOf(question);
    this.menuConfig.preguntas.splice(indice, 1)
  }

  editCategoryBot(menu: Menu){
    this.menuConfig = menu
    this.config = true
  }


  private alertMaxQuestion(){
    this.messageService.add({ key: 'ts', severity: 'warn' , summary: 'Advertencia', detail: 'Limite de preguntas alcanzado' })
  }

}
