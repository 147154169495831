import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../auth/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  public actived: boolean
  public username: String
  public password: String

  constructor(private router: Router, 
    private authService: AuthService,
    private tokenService: TokenService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    
  }

  public login(){
    this.authService.login({"username": this.username, "password": this.password}).subscribe(result => {
      if(result.status == 200) {
        let headers = result.headers
        this.tokenService.setToken(headers.get('Authorization'))
        this.router.navigate(['/admin', this.username,'chatbot'])

      }
    }, error => {
      if (error.status == 403) {
        this.messageService.add({key: 'toast', severity:'error', summary: 'Alert', detail: 'Favor de revisar su usuario y/o contraseña'});
      } else  {
        this.messageService.add({key: 'toast', severity:'error', summary: 'Alert', detail: 'Ha ocurrido un error inesper'});
      }
    })
  }
}
