import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InfoChatbotService } from 'src/app/services/info-chatbot.service';

declare var $: any

@Component({
  selector: 'app-edit-camp',
  templateUrl: './edit-camp.component.html',
  styleUrls: ['./edit-camp.component.scss']
})
export class EditCampComponent implements OnInit {

  public text = new FormControl('');
  public link = new FormControl('');
  public tipoEdit: string
  private information: any;
  public loading: boolean = false

  @Input()
  get info(): any { return this.information; }
  set info(name: any) {
    this.information = name

    if (name?.mensajeOutTurno) this.text.setValue(name.mensajeOutTurno)
    if (name?.mensajeInTurno) this.text.setValue(name.mensajeInTurno)
    if (name?.txMenu) this.text.setValue(name?.txMenu)
    if (name?.txPregunta) this.text.setValue(name?.txPregunta)
    if (name?.nombre) this.text.setValue(name?.nombre)
    if (name?.nombre && name?.link) this.text.setValue(name?.nombre); this.link.setValue(name?.link)
  }

  @Input()
  get tipoEditado(): any { return this.tipoEdit; }
  set tipoEditado(name: any) {
    this.tipoEdit = name
  }

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private serviceUpdate: InfoChatbotService) { }

  ngOnInit(): void {
  }

  public sendMessage(newText: any, link:any): void {
    if (this.info.mensajeOutTurno) this.info.mensajeOutTurno = newText.value
    if (this.info.mensajeInTurno) this.info.mensajeInTurno = newText.value
    if (this.info.txMenu) this.info.txMenu = newText.value
    if (this.info.txPregunta) this.info.txPregunta = newText.value
    if (this.info.nombre) this.info.nombre = newText.value
    if (this.info.link) this.info.link = link.value
    this.updateInfo(this.info)
  }


  private updateInfo(info: any): void {
    this.loading = true
    switch (this.tipoEdit) {
      case 'pregunta':
        this.serviceUpdate.updatePregunta(info).subscribe(res => {
          this.loading = false
          this.messageEvent.emit(res)
          $('#exampleModal').modal('hide')
        })
        break;
      case 'menu':
        this.serviceUpdate.updateMenu(info).subscribe(res => {
          this.loading = false
          this.messageEvent.emit(res)
          $('#exampleModal').modal('hide')
        })
        break;
      case 'link':
        this.serviceUpdate.updateLink(info).subscribe(res => {
          this.loading = false
          this.messageEvent.emit(res)
          $('#exampleModal').modal('hide')
        })
        break;
      case 'opciones':
        this.serviceUpdate.updateOpcion(info).subscribe(res => {
          this.loading = false
          this.messageEvent.emit(res)
          $('#exampleModal').modal('hide')
        })
        break;
      case 'mensaje1':
        this.serviceUpdate.updateMessage1(info).subscribe(res => {
          this.loading = false
          this.messageEvent.emit(res)
          $('#exampleModal').modal('hide')
        })
        break;
      case 'mensaje2':
        this.serviceUpdate.updateMessage2(info).subscribe(res => {
          this.loading = false
          this.messageEvent.emit(res)
          $('#exampleModal').modal('hide')
        })
        break;
    }

  }


}
