<form *ngIf="!info?.nombre">
  <div class="form-group">
    <label for="text">Mensaje</label>
    <textarea type="text" cols="3" name="text" class="form-control" [formControl]="text"></textarea>
  </div>
  <button *ngIf="!loading" class="btn btn-primary" type="button" (click)="sendMessage(text)">Guardar
    cambios</button>
  <app-spinner-loading *ngIf="loading"></app-spinner-loading>
</form>
<form *ngIf="info?.nombre && info?.link">
  <div class="form-group">
    <label for="text">Mensaje</label>
    <textarea type="text" cols="3" name="text" class="form-control" [formControl]="text"></textarea>
  </div>
  <div class="form-group">
    <label for="text">Link</label>
    <textarea type="text" cols="3" name="link" class="form-control" [formControl]="link"></textarea>
  </div>
  <button *ngIf="!loading" class="btn btn-primary" type="button" (click)="sendMessage(text,link)">Guardar
    cambios</button>
    <app-spinner-loading *ngIf="loading"></app-spinner-loading>
</form>
<form *ngIf="info?.nombre && tipoEdit == 'opciones'">
  <div class="form-group">
    <label for="text">Mensaje</label>
    <textarea type="text" cols="3" name="text" class="form-control" [formControl]="text"></textarea>
  </div>
  <button *ngIf="!loading" class="btn btn-primary" type="button" (click)="sendMessage(text)">Guardar
    cambios</button>
    <app-spinner-loading *ngIf="loading"></app-spinner-loading>
</form>
