import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChatBotObject } from '../models/chatbot';
import { TokenService } from '../auth/token.service';

@Injectable({
  providedIn: 'root'
})
export class InfoChatbotService {
  
  private urlChatBot:string = `${environment.serverUrl}/getChatbot`
  private urlPregunta:string = `${environment.serverUrl}/updatePregunta`
  private urlMenu:string = `${environment.serverUrl}/updateMenu`
  private urlLink:string = `${environment.serverUrl}/updateLink`
  private urlOpciones:string = `${environment.serverUrl}/updateOpcion`
  private urlMessage1:string = `${environment.serverUrl}/updateChatbotIn`
  private urlMessage2:string = `${environment.serverUrl}/updateChatbotOut`
  private urlCheckEmail = `${environment.serverUrl}/resetPassword`
  private urlUpdatePassword = `${environment.serverUrl}/updatePassword`
  private urlCheckToken = `${environment.serverUrl}/checkValidToken`
  private urlChangePassword = `${environment.serverUrl}/checkValidToken`

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  private htttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
    //'Authorization': this.tokenService.getToken()
  })

  getChatBotInfo():Observable<ChatBotObject> {
    return this.http.get<ChatBotObject>(this.urlChatBot,{ headers: this.htttpHeaders})
  }

  updatePregunta(data:any):Observable<any>{
    return this.http.post(this.urlPregunta,data,{ headers: this.htttpHeaders})
  }

  updateMenu(data:any):Observable<any>{
    return this.http.post(this.urlMenu,data,{ headers: this.htttpHeaders})
  }

  updateLink(data:any):Observable<any>{
    return this.http.post(this.urlLink,data,{ headers: this.htttpHeaders})
  }

  updateOpcion(data:any):Observable<any>{
    return this.http.post(this.urlOpciones, data,{ headers: this.htttpHeaders})
  }

  updateMessage1(data:any):Observable<any>{
    return this.http.post(this.urlMessage1, data,{ headers: this.htttpHeaders})
  }

  updateMessage2(data:any):Observable<any>{
    
    return this.http.post(this.urlMessage2, data,{ headers: this.htttpHeaders})
  }

  checkEmail(email: String):Observable<any> {
    return this.http.get(`${this.urlCheckEmail}/${email}`)
  }

  resetPassword(token: any, password: String): Observable<any> {
    let htttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${token}`
    })
    return this.http.post(this.urlUpdatePassword, password, {headers: htttpHeaders})
  }

  checkToken(token: any): Observable<any> {
    let htttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${token}`
    })
    return this.http.get(`${this.urlCheckToken}`, {headers: htttpHeaders})
  }

}
