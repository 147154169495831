import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  private defaultApplicationHeaders = {
    'Content-Type': 'application/json'
  }

  buildRequestHeaders(request): HttpHeaders {
   let headers = undefined
    
    let tokenService = this.injector.get(TokenService)
    
    if(tokenService.getToken()) {
      headers = this.defaultApplicationHeaders;
      headers['Authorization'] = tokenService.getToken()
    } else {
      return request.headers
    }
    
    return new HttpHeaders(headers);
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //var authReq = request.clone();
    const headers = this.buildRequestHeaders(request);
    var authReq = request.clone({ headers });       

    return next.handle(authReq).pipe(catchError(this.handleError))
  }
}
