
    export class Link {
        id: number;
        nombre: string;
        link: string;
    }

    export class Link2 {
        id: number;
        nombre: string;
        link: string;
    }

    export class Pregunta2 {
        id: number;
        orden: number;
        txPregunta: string;
        links: Link2[] = [];
        opciones?: any;
    }

    export class Opcione {
        id: number;
        orden: number;
        nombre: string;
        pregunta: Pregunta2;
    }

    export class Pregunta {
        id: number;
        orden: number;
        txPregunta: string;
        links: Link[] = [];
        opciones: Opcione[] = [];
    }

    export class Menu {
        id: number;
        txMenu: string;
        preguntas: Pregunta[] = [];
    }

    export class ChatBotObject {
        idChatbot: number;
        mensajeInTurno: string;
        mensajeOutTurno: string;
        menus: Menu[] = [];
    }

