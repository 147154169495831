import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = `${environment.serverUrl}/login`

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  login(request: any){
    const contentHeader = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(this.loginUrl, request, { headers: contentHeader, observe: "response", })
  }
}
