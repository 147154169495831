<p-toast position="top-right" key="toast"></p-toast>
<div class="row" style="height: 100%;">
    <div class="col-12">
        <div class="card login shadow p-4">
            <div class="row">
                <div class="col-10 col-md-10">
                    <h4 class="text-login">Inicia sesión en tu cuenta</h4>
                </div>
                <div class="col-2 col-md-2">
                    <img class="logo-login" src="assets/img/ICO_WEB_2020.png" alt="logo">
                </div>
            </div>
            
            <form>
                <div class="form-group p-2">
                    <label for="email">Usuario</label>
                    <input type="text" id="email" [(ngModel)]="username" name="username" class="form-control inp">
                </div>
                <div class="form-group p-2">
                    <label for="password">Contraseña </label> 
                    <input type="password" id="password" [(ngModel)]="password" name="password" class="form-control inp">
                    <a routerLink="/reset-password">¿No recuerdas tu contraseña?</a>
                </div>
                <!--<div class="form-group p-2">
                    <div class="p-field-checkbox">
                        <p-checkbox binary="true" inputId="binary"></p-checkbox>
                        <label style="margin-bottom: 0;" for="binary">Mantener la sesión durante una semana</label>
                    </div>
                </div>-->
                <div class="form-group p-2" (click)="login()">
                    <div class="button">
                        <a style="background-image: none;">
                            Iniciar Sesión
                            <span class="shift">></span>
                        </a>
                        <div class="mask"></div>
                    </div>
                </div>
                <p class="footer-login">Usar un inicio de sesión único (SSO) como alternativa</p>
            </form>
        </div>
    </div>
</div>