import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InfoChatbotService } from 'src/app/services/info-chatbot.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService]
})
export class ResetPasswordComponent implements OnInit {

  public email: String
  public password: String
  public passwordConfirm: String
  public loading = false
  public page = 1
  public validando = false
  private token = ""

  constructor(private messageService: MessageService, private chatbotService: InfoChatbotService, private activeRoute: ActivatedRoute, private router: Router) {
    this.activeRoute.queryParams.subscribe(params => {
      let token = params['tok']
      if (token) {
        this.token = token
        this.validando = true
        this.chatbotService.checkToken(token).subscribe(res => {

          if (res && res == true) {
            this.validando = false
            this.page = 3
          } else {
            this.router.navigate(['/login'])
          }
        }, (error) => {

          if (error.status == 403) {
            this.router.navigate(['/login'])
          } else {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ocurrio un error inesperado' })
          }
        })
      }
    })
  }

  ngOnInit(): void {

  }

  clickChangePassword() {

    if ((this.password && this.passwordConfirm) && (this.password == this.passwordConfirm)) {
      if (this.token) {
        this.loading = true
        this.chatbotService.resetPassword(this.token, this.password).subscribe(res => {
          this.loading = false
          if (res && res == true) {
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Sucess', detail: 'Su contraseña se cambio exitosamente' })
            setTimeout(() => {
              this.router.navigate(['/login'])
            }, 3000)

          } else {
            this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ocurrio un error inesperado' })
          }
        }, () => {
          this.loading = false
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ocurrio un error inesperado' })
        })
      } else {
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ocurrio un error con el token' })
      }

    } else if (this.password != this.passwordConfirm) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Las contraseñas no coinciden' })
    } else {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Favor de ingresar la contraseña nueva' })
    }
  }


  checkEmail() {
    if (!this.email) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ingrese su correo electrónico' })
      return
    }
    this.loading = true
    this.chatbotService.checkEmail(this.email).subscribe(res => {
      this.loading = false
      if (res && res == true) {
        this.page = 2
        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 4000)
      } else {
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ocurrio un error inesperado' })
      }
    }, (error) => {
      this.loading = false
      if (error.status == 404) {
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: error.error.message })
      } else {
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Error', detail: 'Ocurrio un error inesperado' })
      }
    })
  }
}
