<div class="p-5" style="position: relative; z-index: 9;">
  <h1 class="text-center p-2">Crear ChatBot</h1>
  <div class="row">
    <div class="col-9" *ngIf="!config">
      <div class="row overhide pb-3">
        <div class="col-12">
          <h3>Mensajes de Bienvenida</h3>
        </div>
        <div class="col-6 pb-2">
          <p>Mensaje dentro de Atención</p>
          <textarea class="form-control" rows="5" style="resize: none;" placeholder="Escribe aquí..."></textarea>
        </div>
        <div class="col-6 pb-2">
          <p>Mensaje fuera de Atención</p>
          <textarea class="form-control" rows="5" style="resize: none;" placeholder="Escribe aquí..."></textarea>
        </div>
        <div class="col-12 display-card" *ngIf="createMain">
          <div class="row">
            <div class="col-6 p-0">
              <span class="p-float-label mt-3">
                <input class="w-100" type="number" id="inputtext" pInputText [(ngModel)]="sizeMenu">
                <label for="inputtext">Ingrese el número de items</label>
              </span>
            </div>
            <div class="col-6 pt-3">
              <button pButton pRipple type="button" label="Agregar" class="p-button-outlined p-button-info"
                (click)="createCategories()"></button>
            </div>
          </div>
        </div>
        <div class="col-12 p-0" *ngIf="menus.length >=1">
          <h3 class="pl-3 pt-2">Menú</h3>
          <div class="row row-cols-3">
            <div class="col p-0 display-card" *ngFor="let menu of menus; let i = index;">
              <div class="card m-3">
                <div *ngIf="menu.txMenu">
                  <p class="p-2 text-center"> {{ menu.txMenu }}</p>
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                    class="p-button-rounded p-button-secondary p-button-outlined"
                    (click)="editCategoryBot(menu)"></button>
                </div>
                <div class="p-2 d-flex justify-content-center align-items-center" *ngIf="!menu.txMenu">
                  <button pButton pRipple type="button" icon="pi pi-cog"
                    class="p-button-rounded p-button-secondary p-button-outlined"
                    (click)="editCategoryBot(menu)"></button>
                  <p class="ml-2 mb-0">configurar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9 display-card-down" *ngIf="config">
      <div class="p-3">
        <button pButton pRipple type="button" icon="pi pi-arrow-left"
          class="p-button-rounded p-button-secondary p-button-outlined" (click)="config = false"></button>
      </div>
      <div class="row">
        <div class="col-12">
          <h3 class="py-2">Nombre de la categoria</h3>
          <span class="p-float-label mt-3">
            <input class="w-100" type="text" id="nameCategoria" pInputText [(ngModel)]="menuConfig.txMenu">
            <label for="nameCategoria">Ingrese el nombre</label>
          </span>
        </div>
        <div class="col-12" *ngIf="menuConfig?.preguntas.length >=1">
            <h3 class="py-2">Preguntas</h3>
        </div>
        <div class="col-6 mt-2 display-card-down" *ngFor="let question of menuConfig.preguntas; let i = index;">
          <div class="card m-2">
            <span class="p-float-label">
              <input class="w-100" type="text" id="pregunta{{i}}" pInputText [(ngModel)]="question.txPregunta">
              <label for="pregunta{{i}}">Ingrese la pregunta</label>
            </span>
            <div class="row">
              <div class="col">

              </div>
              <div class="col">

              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button pButton pRipple type="button" icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined m-2" (click)="removeQuestion(question)"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card shadow p-2">
        <p>herramientas</p>
        <div class="d-flex align-items-center">
          <button pButton pRipple type="button" icon="pi pi-bars"
            class="p-button-rounded p-button-secondary p-button-outlined" (click)="createMain = true"></button>
          <p class="ml-2 mb-0">crear menu</p>
        </div>
        <div *ngIf="config">
          <div class="my-2 d-flex align-items-center">
            <button pButton pRipple type="button" icon="pi pi-question"
              class="p-button-rounded p-button-secondary p-button-outlined"
              (click)="createQuestionByCategory()"></button>
            <p class="ml-2 mb-0">agregar preguntas ({{ menuConfig.preguntas.length}})</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast key="ts"></p-toast>
