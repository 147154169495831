import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CreateBotComponent } from './create-bot/create-bot.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset_password/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'admin/:nick', loadChildren:()=> import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'createBot', component: CreateBotComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
